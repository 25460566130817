import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNotify } from '../website/Components/Notify';
import { SendOTPMail } from '../website/Components/Mailer';

export default function Verify_by_otp({ email, phoneNumber, data, onSuccess, onError }) {

    const Notify = useNotify();

    const [OTP, setOTP] = useState(null);
    // const [IsVerifyOTP, setIsVerifyOTP] = useState(false);
    const [verificationType, setVerificationType] = useState('Email');
    const [remainingTime, setremainingTime] = useState('00:00');
    const [IsFail, setIsFail] = useState(false);

    //#region Handle OTP

    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus on next input box
        if (element.nextSibling && element.value !== "") {
            element.nextSibling.focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Entered OTP is " + otp.join(""));
        // Add your verification logic here
    };

    //#endregion

    function generateOTP() {
        // Generate a 6-digit random number as OTP
        return Math.floor(100000 + Math.random() * 900000).toString();
    }

    function startTiming() {
        let rTime = 300;

        const Interval = setInterval(() => {
            if (rTime > 0) {
                let time = Math.floor(rTime / 60).toString().padStart(2, '0') + ":" + (rTime % 60).toString().padStart(2, '0');
                rTime--;
                setremainingTime(time);
            }
            else {
                setremainingTime('00:00');
                clearInterval(Interval);
            }
        }, 1000);
    }

    const sendOTP = async () => {
        const tmpOTP = generateOTP();
        setOTP(tmpOTP);

        setIsFail(false);
        // alert("Your OTP is : " + tmpOTP);
        SendOTPMail(data.name, email, tmpOTP);

        if (verificationType === 'Email') {
            // Send OTP on mail
        }
        else {
            // Send OTP on SMS
        }

        startTiming();
        // setIsVerifyOTP(true);
    }

    const VerifyOTP = async () => {
        const userOTP = otp.join("");

        if (userOTP === OTP) {
            await onSuccess(data);
        }
        else {
            setIsFail(true);
            Notify('error', 'OTP is wrong');
        }
    }

    useEffect(() => {
        // console.log(OTP);
        
        // if (!IsVerifyOTP) {
            // if (email && !phoneNumber) {
                setVerificationType('Email');
                sendOTP();
        //     }
        //     else if (!email && phoneNumber) {
        //         setVerificationType('SMS');
        //         sendOTP();
        //     }
        //     else if (!email && !phoneNumber) {
        //         onError();
        //     }
        // }
    }, []);

    return (
        <div className='overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 max-h-full' style={{ backgroundColor: 'rgba(0, 0, 0, 0.50)' }}>
            {/* {IsVerifyOTP ? */}
                (<div className='relative p-4 w-full max-w-md max-h-full mx-auto mt-32 bg-white'>
                    <div className="flex items-center justify-between light:border-gray-600">
                        <label className='text-sm text-left'>we have send otp on {email}. please check and verify.</label>
                        <button type="button" onClick={onError} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex justify-between mt-4">
                        {otp.map((data, index) => {
                            return (
                                <input
                                    className="w-10 h-10 m-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    type="text"
                                    name="otp"
                                    maxLength="1"
                                    key={index}
                                    value={data}
                                    onChange={(e) => handleChange(e.target, index)}
                                    onFocus={(e) => e.target.select()}
                                />
                            );
                        })}
                    </div>
                    <div className='w-full flex mb-2'>
                        <label className='mt-3 text-red-400 text-md'>{remainingTime}</label>
                    </div>
                    {remainingTime !== "00:00" ?
                        (<button className='p-5 bg-blue-500 text-white py-2 hover:bg-blue-600 transition duration-300 rounded w-full' onClick={() => VerifyOTP()}>Verify</button>)
                        :
                        (<button className='p-5 bg-blue-500 text-white py-2 hover:bg-blue-600 transition duration-300 rounded w-full' onClick={() => sendOTP()}>Resend</button>)
                    }
                </div>)
                {/* :
                 (<div className='relative p-4 w-full max-w-md bg-white max-h-full mx-auto mt-32'>
                     <div className='flex' style={{ alignItems: 'center' }}>
                         <h3 className='mr-4'>Verify by:</h3>
                         <FormControl component="fieldset" style={{ flexDirection: 'row' }}>
                             <RadioGroup
                                 className='flex'
                                 aria-label="verifyBy"
                                 name="verifyBy"
                                 value={verificationType}
                                 onChange={(e) => setVerificationType(e.target.value)}
                             >
                                 <FormControlLabel
                                     value="Email"
                                     control={<Radio />}
                                     label="Email"
                                 />
                                 <FormControlLabel
                                     value="SMS"
                                     control={<Radio />}
                                     label="SMS"
                                 />
                             </RadioGroup>
                         </FormControl>
                     </div>
                     <button className='p-5 bg-blue-500 text-white py-2 hover:bg-blue-600 transition duration-300 rounded w-full' onClick={() => sendOTP()}>Send OTP</button>
                 </div>)
             } */}
        </div>
    )
}
