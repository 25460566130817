import React, { useState } from 'react'
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router';

import Verify_by_otp from './verify_by_otp';
import { IsEmail, IsPhone } from '../Database/validation';
import { useData } from '../website/Data';
import { useNotify } from '../website/Components/Notify';
import { UpdateData } from '../Database/db_helper';
import { dbCollections } from '../Database/tables';
import { Field, Form, Formik } from 'formik';
import { TextField } from '@mui/material';

import logo from '../website/Images/logo/logo.png';

export default function ChangePassword() {
    const location = useLocation();
    const { lstCustomer } = useData();
    const Notify = useNotify();
    const navigate = useNavigate();

    const { forgot } = location.state || {};
    const [popup, setPopup] = useState('');

    const handleSubmit = (values) => {
        // Yup Validations

        if (forgot !== "True" && !values.old_password) {
            Notify('error', 'Old Password is Required.');
            return;
        }

        if (!values.password) {
            Notify('error', 'Password is Required.');
            return;
        }

        if (values.password !== values.confirm_password) {
            Notify('error', 'confirm password and new password not match.');
            return;
        }

        const objCustomer = lstCustomer.find(cust => cust.email === values.email || cust.phone_number === values.email);
        if (!objCustomer) {
            Notify('error', 'user not found with this email or phone number, please check it again or register as new account.');
            return;
        }

        if (forgot !== "True" && objCustomer.password !== values.old_password) {
            Notify('error', 'old password is incorrect.');
            return;
        }

        let email = null;
        let phoneNumber = null;

        if (IsEmail(values.email)) {
            email = values.email;
        }
        else if (IsPhone(values.email)) {
            email = objCustomer.email;
            phoneNumber = values.email;
        }

        setPopup(<Verify_by_otp email={email} phoneNumber={phoneNumber} data={values} onSuccess={UpdatePassword} onError={ClosePopup} />);
    }

    const UpdatePassword = async (values) => {
        ClosePopup();
        const { id, ...objCustomer } = lstCustomer.find(cust => cust.email === values.email || cust.phone_number === values.email);
        if (id && (id === Cookies.get('CustomerId') || forgot === "True")) {
            objCustomer.password = values.password;
            if (await UpdateData(dbCollections.Customers, id, objCustomer)) {
                Notify('Success', 'Password Updated Successfully.');
                // send Mail
                // send SMS

                navigate('/registration')
            }
            else {
                Notify('error', 'Password Change Fail.');
            }
        }
        else {
            Notify('error', 'User not found.');
        }
    }

    const ClosePopup = () => {
        setPopup('');
    }

    return (
        <div className="registration-container">
            <div className="registration-form-box absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-md px-14 py-10 z-10" style={{ top: '50vh' }}>
                <img src={logo} alt='All Jewelz' className='mx-auto w-32'></img>
                <h1 className="text-xl mt-2 font-bold mb-4">Welcome to All Jewelz!</h1>
                <Formik initialValues={{ email: '', old_password: '', password: '', confirm_password: '' }} onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                    setSubmitting(false);
                }}>
                    {({ errors, touched }) => (
                        <Form className='text-left'>
                            <Field
                                name="email"
                                as={TextField}
                                label="Email OR Phone Number"
                                type="text"
                                error={touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                fullWidth
                                required
                                margin="normal"
                            />
                            {forgot !== "True" && (<Field
                                name="old_password"
                                as={TextField}
                                label="Old Password"
                                type="password"
                                error={touched.old_password && !!errors.old_password}
                                helperText={touched.old_password && errors.old_password}
                                fullWidth
                                margin="normal"
                                required
                            />)}
                            <Field
                                name="password"
                                as={TextField}
                                label="New Password"
                                type="password"
                                error={touched.password && !!errors.password}
                                helperText={touched.password && errors.password}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <Field
                                name="confirm_password"
                                as={TextField}
                                label="Confirm Password"
                                type="password"
                                error={touched.confirm_password && !!errors.confirm_password}
                                helperText={touched.confirm_password && errors.confirm_password}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <button type="submit" className="w-full py-2 mt-4 rounded-md bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-50">
                                Change Password
                            </button>
                        </Form>)}
                </Formik>
            </div >
            {popup}
        </div >
    )
}
