import React from 'react'

export default function Refund_return_policy() {
    return (
        <div className='lg:p-24 md:p-20 sm:p-14 p-10 text-justify'>
            <p className='mt-3'>At AllJewelz, we offer a complimentary 30-day return policy with FREE return shipping via FedEx for U.S. orders, so you can make sure your purchase is perfect. If for any reason you are not completely satisfied with your order, return it within 30 days from when it ships.</p>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Free 30 Day Returns</h1>
            <p className='mt-3'>If you receive a damaged or incorrectly shipped item, please notify us within 24 hours of delivery. Provide us with a picture of the damaged item, and we will gladly assist you in resolving the issue. Customer will have to arrange for the return of the item, and upon receipt and inspection, we will refund the value of the damaged item, including any shipping fees, as store credit for future purchases.</p>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Return Policies</h1>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>All Returns</h1>
            <ul className='list-[circle] ml-5'>
                <li>Once you request your return, and it is accepted, you have 7 days to ship the return.</li>
                <li>All returned products must be in their original, unworn condition and accompanied by all original documentation that was provided with the purchase.</li>
                <li>Returns without the lab grown diamond certificate (where applicable) are subject to a $100 replacement fee for each missing card.</li>
                <li>Items that have been modified, engraved, or resized by a company other than AllJewelz may not be returned.</li>
                <li>Returns showing signs of wear or damage in any way will not be accepted for return.</li>
                <li>Packages without a Return Shipping Authorization Number or packages that were refused for delivery will not be accepted for refund. </li>
                <li>We reserve the right to limit access to the website and/or terminate the account of Returns Abusers. See our full policy regarding Returns Abusers in the Returns section of our Terms & Conditions page.</li>
            </ul>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>Returns to Original Payment</h1>
            <ul className='list-[circle] ml-5'>
                <li>Most items can be returned for refund to your original form of payment.</li>
                <li>All pre-designed styles and Design Your Ring styles may be returned for a full refund back to your original payment method.</li>
            </ul>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>Return for Store Credit Only</h1>
            <ul className='list-[circle] ml-5'>
                <li>High Jewelry, Special Orders, and Truly Custom orders are available to return for store credit only. If the item is returnable for store credit only, we will disclose this on the product detail page. The Lifetime Manufacturing Warranty and Repair processes are still applicable and can be utilized at any time.</li>
                <li>All special orders, including Truly Custom special orders, may be returned for store credit only. Should you choose for the store credit be used for a new custom piece through Truly Custom, this is considered a re-design and is eligible one time only. Once going through the re-design process and placing the new order using store credit, the re-designed piece is final sale. The Lifetime Manufacturing Warranty and Repair process are still applicable and can be utilized at any time. Our Lifetime Manufacturing Warranty applies to all Truly Custom purchases. Please contact truly.custom@alljewelz.com to speak with a Jewelry Specialist to begin your re-design.</li>
                <li>Returns without the lab grown diamond certificate (where applicable) are subject to a $100 replacement fee for each missing card.</li>
                <li>Items that have been modified, engraved, or resized by a company other than AllJewelz may not be returned.</li>
                <li>Returns showing signs of wear or damage in any way will not be accepted for return.</li>
                <li>Packages without a Return Shipping Authorization Number or packages that were refused for delivery will not be accepted for refund. </li>
                <li>We reserve the right to limit access to the website and/or terminate the account of Returns Abusers. See our full policy regarding Returns Abusers in the Returns section of our Terms & Conditions page.</li>
            </ul>
            
            <h1 className='text-xl text-pink-600 mt-5 mb-3'>How to Return an Order</h1>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>1. Initiate Your Return</h1>
            <p className='ml-5'>If your order is eligible, we will email you a FedEx shipping label, which will include your Return Authorization Number. The Return Authorization Number will be valid for seven (7) days. There are three ways to initiate your return:</p>
            <ul className='list-[circle] ml-10'>
                <li><b>Online Account:</b> If you placed your order using your online account, log into your account and go to Order. You can choose which orders and items you would like to return.</li>
                <li><b>Order Lookup:</b> If you placed your order as a guest, you can enter your information here and choose which items you would like to return.</li>
            </ul>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>2. Prepare Your Return</h1>
            <ul className='list-[circle] ml-10'>
                <li>Include all original packaging, your return packing slip, and any diamond certificates. If the diamond certificate is not returned, $100 will be withheld from your refund.</li>
                <li>Enclose your jewelry item(s) inside the original jewelry box.</li>
                <li>Place the jewelry box (containing your jewelry) inside a shipping box. We recommend using the original shipping box from your order, or you may acquire a FedEx box from your local FedEx store.</li>
                <li>Place the return packing slip inside the shipping box with the jewelry.</li>
                <li>Once the items are placed in a larger shipment box, seal the box and affix the prepaid label to the outside of the shipping box.</li>
                <li>For security reasons, please refrain from writing jewelry, diamonds, or other related words anywhere on the package.</li>
            </ul>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>3. Ship Your Return</h1>
            <ul className='list-[circle] ml-10'>
                <li>Drop it off at your nearest FedEx location. AllJewelz and FedEx are not responsible for packages dropped off in FedEx Drop Boxes or Non-FedEx staffed locations.</li>
                <li>Obtain a FedEx receipt to confirm that the item has been shipped as this receipt will act as proof of insurance.</li>
                <li>If you decide to use a different shipping method you can ship it back by any carrier, however you will be responsible for the return shipping charges. For your protection, please ship your item fully secured with a signature required upon delivery and request a return receipt for confirmation that your shipment has reached AllJewelz. AllJewelz is not responsible for items lost or damaged during the return shipping process.</li>
            </ul>

            <h1 className='text-xl text-pink-600 mt-5 mb-3'>Refund Process</h1>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>1. Receipt and Inspection</h1>
            <ul className='list-[circle] ml-10'>
                <li>Once we receive the returned order, our Quality Assurance team will inspect and verify the condition of the returned item.</li>
                <li>Receipt and inspection time may take up to seven (7) business days.</li>
            </ul>
            <h1 className='text-lg text-pink-600 mt-5 mb-2'>2. Refund</h1>
            <ul className='list-[circle] ml-10'>
                <li>Refunds for special orders, including Truly Custom special orders, will be issued as a store credit to your account that was used for the purchase.  You will still be responsible for the payments on your original payment method.</li>
                <li>Refunds for pre-designed styles and Design Your Ring styles will be issued via the original payment method or by check if the original payment method was bank wire, cash, or certain other payments requiring special processing.</li>
                <li>For credit card refunds, please allow up to one monthly billing cycle in order for your credit to appear.</li>
            </ul>
        </div>
    )
}
