const eMsg = {
    Success: 1,
    Info: 2,
    Error: 3
}

const eStatus = {
    Active: 1,
    Deactive: 2,
    Delete: 3
}

const eOrderStatus = {
    Pending: 1,
    Processing: 2,
    Shipping: 3,
    Complete: 4,
    Cancel: 5,
    Return: 6
}

const eDiamondType = {
    EF_VVS: "EF/VVS",
    GH_VS: "GH/VS",
    HI_SI1: "HI/SI-1",
    I_I1: "I/I1"
}

export { eMsg, eStatus, eOrderStatus, eDiamondType };

export function GetEnumName(Enum, value) {
    return Object.keys(Enum).find(key => Enum[key] === value);
}