import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';

import { useData } from '../../Data';
import { useNotify } from '../../Components/Notify';

import imgPayment from '../../Images/trust_badge.jpg';

import Dropdown from '../../Components/Dropdown';
import Description from './Description';
import { CartItems, dbCollections } from '../../../Database/tables';
import { InsertData } from '../../../Database/db_helper';

export default function Details({ product }) {

  const navigate = useNavigate();
  const Notify = useNotify();

  const { lstMaterial, lstPurity, lstDiamond, lstCategory, objConfig, lstWishlist, ToggleWishlist } = useData();

  //#region Image Magnifire

  const containerRef = useRef(null);
  const magnifierRef = useRef(null);
  const [magnifierStyle, setMagnifierStyle] = useState({});
  const [isMagnifierVisible, setIsMagnifierVisible] = useState(false);
  const zoomFactor = 2;

  useEffect(() => {
    if (containerRef.current && magnifierRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      magnifierRef.current.style.width = `${containerWidth * 2}px`;
      magnifierRef.current.style.height = `${containerWidth * 2}px`;
    }
  }, []);

  const handleMouseMove = (e) => {
    if (!containerRef.current || !magnifierRef.current) return;

    const container = containerRef.current;
    const rect = container.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (x > 0 && y > 0 && x < container.offsetWidth && y < container.offsetHeight) {
      const bgPosX = -((x * zoomFactor) - magnifierRef.current.offsetWidth / 2);
      const bgPosY = -((y * zoomFactor) - magnifierRef.current.offsetHeight / 2);

      setMagnifierStyle({
        display: 'block',
        // left: `${x - magnifierRef.current.offsetWidth / 2}px`,
        // top: `${y - magnifierRef.current.offsetHeight / 2}px`,
        left: `${x - ((magnifierRef.current.offsetWidth / 2) - 50)}px`,
        top: `${y}px`,
        backgroundPosition: `${bgPosX}px ${bgPosY}px`,
        backgroundImage: `url('${lstImages[CurrentImageIndex]?.DownloadURL ?? lstImages[0]?.DownloadURL}')`,
        backgroundSize: `${container.offsetWidth * zoomFactor}px ${container.offsetHeight * zoomFactor}px`,
      });

      setIsMagnifierVisible(true);
    } else {
      setIsMagnifierVisible(false);
    }
  };

  const handleMouseLeave = () => {
    setIsMagnifierVisible(false);
  };

  //#endregion

  const [MaterialWeight, setWeight] = useState();
  const [objDiamondSize, setobjDiamondSize] = useState();
  const [objColor, setobjColor] = useState();
  const [objKarat, setobjKarat] = useState();
  const [ProductSizes, setProductSizes] = useState('');


  const [Price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [CurrentImageIndex, setCurrentImageIndex] = useState(0);
  const [lstImages, setlstImages] = useState([]);

  //#region  Load Data

  useEffect(() => {
    const Sizes = loadSize(true);
    setProductSizes(Sizes);
  }, [MaterialWeight]);

  const loadSize = (setDefault = false) => {
    if (product.MaterialDetails) {
      if (product.MaterialDetails.length > 1) {
        const lstsize = product.MaterialDetails.map(material => [material.Size, material.MaterialWeight]);

        const URL = GetSizeVideo(product.Category);

        return (
          <div className="mt-8">
            <span className="whitespace-nowrap mr-3">Size:</span>
            <Dropdown className='p-2 ml-3 border-black' Value={MaterialWeight} items={lstsize} onSelectChange={setWeight} DefaultSelect={true} />
            {URL && (<div className='mt-5'>
              <video controls autoPlay loop className='w-28 h-28 rounded-full'>
                <source loading='lazy' src={URL} type="video/mp4"></source>
              </video>
            </div>)}
          </div>
        )

      }
      else if (product.MaterialDetails.length === 1 && (!MaterialWeight || setDefault)) {
        setWeight(product.MaterialDetails[0].MaterialWeight);
      }
    }
  }

  const loadDiamondSize = () => {
    if (product.MaterialDetails) {
      const objMaterial = product.MaterialDetails.find(material => material.MaterialWeight === parseFloat(MaterialWeight));

      if (objMaterial) {
        if (!objDiamondSize && objMaterial.DiamondDetails.length > 0) {
          setobjDiamondSize(objMaterial.DiamondDetails[0]);
        }

        if (objMaterial.DiamondDetails.length > 0 && objMaterial.DiamondDetails[0].DiamondId !== 0) {
          return (<div className="mt-7">
            <span className="whitespace-nowrap">Diamond Carat:</span>
            <div className="mt-3 d-flex overflow-auto cursor-pointer">
              {objMaterial.DiamondDetails.map(DDetail => DDetail.DiamondId !== 0 && (<span key={DDetail.DiamondId} className={`swatch-square w-20 text-center whitespace-nowrap hover:text-white hover:bg-pink-500 ${objDiamondSize && DDetail.Carat === objDiamondSize.Carat ? 'text-white bg-pink-500' : ''}`} onClick={() => setobjDiamondSize(DDetail)}>{DDetail.Carat}</span>))}
            </div>
          </div>
          )
        }
      }
    }
  }

  const GetSizeVideo = (CategoryId) => {
    if (CategoryId && CategoryId !== 0) {
      const tmpobjCategory = lstCategory.find(category => category.id === CategoryId);
      if (tmpobjCategory && tmpobjCategory.SizeVideoDownloadURL) {
        return tmpobjCategory.SizeVideoDownloadURL;
      }
      else if (tmpobjCategory) {
        return GetSizeVideo(tmpobjCategory.ParentId);
      }
    }
    else {
      return '';
    }
  }

  useEffect(() => {
    if (!objColor && objDiamondSize && objDiamondSize.Images.length > 0 && lstMaterial.length > 0) {
      const objMaterial = lstMaterial.find(material => material.id === objDiamondSize.Images[0].MaterialId);
      if (objMaterial) {
        setobjColor(objMaterial);
      }
    }
  }, [objDiamondSize, lstMaterial]);

  const loadColors = useMemo(() => {
    return () => {
      if (objDiamondSize) {
        return (<div className="mt-7">
          <span className="whitespace-nowrap">Color:</span>
          <div className="mt-1 d-flex">
            <ul className="flex flex-row justify-center items-center space-x-2">
              {
                objDiamondSize.Images.map((ImgMaterial, Index) => {
                  const objMaterial = lstMaterial.find(material => material.id === ImgMaterial.MaterialId);

                  if (objMaterial) {
                    return (<li key={Index} onClick={() => setobjColor(objMaterial)} >
                      <span title={`${objMaterial.Material}`} className={`block p-1 border-2 hover:border-[${objMaterial.Color}] rounded-full hover:scale-105 duration-300 ${objColor && objMaterial.Color === objColor.Color ? `border-[${objColor.Color}]` : ''}`}>
                        <a className={`block w-3 h-3  bg-[${objMaterial.Color}] rounded-full`}></a>
                      </span>
                    </li>);
                  }
                })
              }
            </ul>
          </div>
        </div>
        );
      }
    };
  }, [objDiamondSize, lstMaterial]);

  const loadKarat = () => {
    if (objColor) {
      const tmplstPurity = lstPurity.filter(purity => purity.MaterialId === objColor.id);
      if (tmplstPurity.length > 0) {
        if (!objKarat) {
          setobjKarat(tmplstPurity[0]);
        }
        return (<div className="mt-5">
          <span className="whitespace-nowrap">Gold Karate:</span>
          <div className="mt-3">
            {tmplstPurity.map((purity, Index) => (
              <span key={Index} className={`swatch-square hover:text-white hover:bg-pink-500 ${objKarat && purity.Karat === objKarat.Karat ? 'text-white bg-pink-500' : ''}`} onClick={() => setobjKarat(purity)}>{purity.Karat}k</span>
            ))
            }
          </div>
        </div>
        )
      }
    }
  }

  useEffect(() => {
    let DiamondPrice = 0;
    if (lstDiamond && objDiamondSize) {
      objDiamondSize.Diamonds.forEach(DSize => {
        const objDiamond = lstDiamond.find(diamond => diamond.id === DSize.DiamondId);
        if (objDiamond) {
          DiamondPrice += DSize.DiamondCount * objDiamond.Price;
        }
      });
    }

    const tmplstPurity = lstPurity.filter(purity => purity.MaterialId === objColor?.id);
    let MaterialPrice = 0;
    if (MaterialWeight && objColor && (tmplstPurity.length === 0 || objKarat)) {
      MaterialPrice = MaterialWeight * (objKarat ? Math.round(objColor.Price * objKarat.Karat / 24) : objColor.Price);
    }

    MaterialPrice += Math.round(MaterialPrice * 5 / 100);
    const MakingCost = Math.round(MaterialPrice * (product?.MakingCharges ?? 100) / 100);
    let tmpPrice = Math.round(DiamondPrice + MaterialPrice + MakingCost + (product?.ExtraMaterialCharge ?? 0));
    const GSTPrice = tmpPrice * objConfig.GST / 100;
    setPrice(parseInt(tmpPrice + GSTPrice));

  }, [objDiamondSize, MaterialWeight, objColor, objKarat]);

  useEffect(() => {
    if (objDiamondSize && objColor) {
      const tmplstImages = objDiamondSize.Images.find(Img => Img.MaterialId === objColor.id);
      setlstImages(tmplstImages.Image);
    }
  }, [objDiamondSize, MaterialWeight, objColor]);

  //#endregion


  //#region Cart Manage

  const AddToCart = async () => {
    const CustomerId = Cookies.get('CustomerId');
    if (!CustomerId) {
      navigate('/registration');
      return;
    }
    else if (product && objDiamondSize && objColor && Price) {
      const objCartItem = { ...CartItems };
      objCartItem.customer_id = CustomerId;
      objCartItem.product_id = product.id;
      objCartItem.quantity = quantity;
      objCartItem.name = product.ProductName;
      objCartItem.material_id = objColor.id;
      objCartItem.product_size = product?.MaterialDetails.length > 1 ? product?.MaterialDetails[0].Size : '';
      objCartItem.material_weight = MaterialWeight;
      objCartItem.price = Price;
      objCartItem.image_url = lstImages[0]?.DownloadURL ?? '';
      objCartItem.karat_id = objKarat?.id ?? '';

      // set Diamond Details
      objCartItem.diamond_id = objDiamondSize.DiamondId;
      let DDetail = '';
      objDiamondSize.Diamonds.forEach(D => { DDetail += `Size: ${D.DiamondSize} qty: ${D.DiamondCount}\n` });
      objCartItem.diamond_details = DDetail;

      let IsInserted = await InsertData(dbCollections.CartItems, objCartItem);
      if (IsInserted) {
        Notify('success', 'Item Added to cart successfully.');
      }
      else {
        Notify('error', 'Sorry!\nItem Not added into cart.');
      }

    }
  }

  //#endregion

  return product && (
    <>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2">
        <div>
          <div className="grid-col-lg-1">
            <div className="single-product-images-wrapper single-product-gallery slick-initialized slick-slider">
              <div className="slick-list draggable">
                <div className="woocommerce-product-gallery__image" style={{ width: '100%', display: 'inline-block', height: '100%', overflow: 'auto' }}>
                  <a tabIndex="0" style={{ position: 'relative', overflow: 'visisble', cursor: 'none' }} ref={containerRef}>
                    <img src={lstImages[CurrentImageIndex]?.DownloadURL ?? lstImages[0]?.DownloadURL} alt='Product Image' loading='lazy' className="wp-post-image" onMouseMove={(e) => handleMouseMove(e)} onMouseLeave={() => handleMouseLeave()} />
                  </a>
                  <div
                    className="magnifier absolute border-2 border-black rounded-full shadow-lg pointer-events-none"
                    ref={magnifierRef}
                    style={{ ...magnifierStyle, display: isMagnifierVisible ? 'block' : 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-col-lg-1 mt-2">
            <div className="slick-list draggable" style={{ overflow: 'auto' }}>
              <div className="slick-track p-4 flex">
                {
                  lstImages.map((Img, Index) => (
                    <div className={`slick-slide slick-current ml-1 ${Index === CurrentImageIndex ? 'slick-active' : ''}`} onClick={() => setCurrentImageIndex(Index)}>
                      <div>
                        <div className="pls-gallery-thumbnail-image" style={{ width: '100%', display: 'inline-block' }}>
                          <img width="150" height="150" loading='lazy' src={Img.DownloadURL} className="attachment-150x150 size-150x150" />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className='text-left'>
          <h1 className="text-3xl font-semibold">{product.ProductName}</h1>
          <div className="mt-2 d-flex">
          </div>
          <p className="price">${Price}.00</p>
          <div className="mt-5">{product.Description}</div>

          <div className="mt-10">
            {loadColors()}
            {loadKarat()}
            {loadDiamondSize()}
            {ProductSizes}
          </div>

          <div className='md:flex mt-7'>
            <div className="flex items-center border-gray-100">
              <span className="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50" onClick={() => { quantity > 1 ? setQuantity(quantity - 1) : setQuantity(quantity) }}> - </span>
              <input className="w-20 p-2 border bg-white text-center text-xs outline-none" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} min="1" />
              <span className="cursor-pointer rounded-r bg-gray-100 py-1 px-3 mr-3 duration-100 hover:bg-blue-500 hover:text-blue-50" onClick={() => setQuantity(quantity + 1)}> + </span>
            </div>
            <button onClick={() => AddToCart()} className="h-10 hover:bg-black hover:text-white mt-2 py-2 px-10">Add to cart</button>
          </div>
          <div className={`mt-5 cursor-pointer ${lstWishlist.find(id => id === product.id) ? 'text-pink-500' : 'hover:text-pink-500'}`} onClick={() => ToggleWishlist(product.id)}>
            <i className="fa fa-heart-o"></i>
            <span className='ml-2'>Add to wishlist</span>
          </div>
          <div className="mt-1">
            <i className='fa fa-repeat'></i>
            <span className='ml-2'>Delivery &amp; Return</span>
            <i className='fa fa-question-circle-o ml-20px'></i>
            <span className="ml-2">Ask a Question</span>
          </div>
          <div className="mt-5">
            <span className='font-semibold'>Guaranteed Safe Checkout :</span>
            <img src={imgPayment} loading='lazy' alt="Trues Badge" className='mt-2' />
          </div>

          <div className="mt-3">
            Share:
            <a href="#" rel="external" target="_blank" className="social-facebook ml-2">
              <i className="fa fa-facebook" style={{ color: '#087BEA' }}></i>
            </a>
            <a href="#" rel="external" target="_blank" className="social-twitter ml-2">
              <i className="fa fa-twitter" style={{ color: '#1DA1F2' }}></i>
            </a>
            <a href="#" rel="external" target="_blank" className="social-linkedin ml-2">
              <i className="fa fa-linkedin" style={{ color: '#0077B5' }}></i>
            </a>
            <a href="#" rel="external" target="_blank" className="social-telegram ml-2">
              <i className="fa fa-telegram" style={{ color: '#25A3E1' }}></i>
            </a>
            <a href="#" rel="external" target="_blank" className="social-pinterest ml-2">
              <i className="fa fa-pinterest" style={{ color: 'red' }}></i>
            </a>
          </div>
        </div>
      </div>
      <Description Material={objColor} Diamonds={objDiamondSize} lstDiamond={lstDiamond} Karat={objKarat} Weight={MaterialWeight} Price={Price} config={objConfig} ExtraMaterialCharge={product.ExtraMaterialCharge} MakingCharge={product.MakingCharges} />
    </>
  )
}