import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Customers, dbCollections } from '../../../Database/tables';
import { InsertData, UpdateData } from '../../../Database/db_helper';
import { useData } from '../../Data';
import { useNotify } from '../../Components/Notify';

import Verify_by_otp from '../../../Login/verify_by_otp';

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required('name is required.'),
  email: Yup.string().required('email is required.').email('email is invalid.'),
  phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
  // home_address1: Yup.string().required('address is required.'),
  // home_address2: Yup.string(),
  // home_city: Yup.string().required('city is required.'),
  // home_state: Yup.string().required('please select state'),
  // home_country: Yup.string().required('please select country.'),
  // office_address1: Yup.string().required('address is required.'),
  // office_address2: Yup.string(),
  // office_city: Yup.string().required('city is required.'),
  // office_state: Yup.string().required('please select state'),
  // office_country: Yup.string().required('please select country.')
});

export default function Profile() {
  const { lstCustomer } = useData();
  const Notify = useNotify();

  const countries = ['United States'];
  const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
  const [h_cities, setHcities] = useState([]);
  const [o_cities, setOcities] = useState([]);
  var [objCustomer, setObjCustomer] = useState(Customers);
  const [popup, setPopup] = useState('');
  const [isload, setisload] = useState(true);

  //#region  Load Data

  const loadHomeCities = async (state) => {
    try {
      const response = await fetch(`https://countriesnow.space/api/v0.1/countries/state/cities/q?country=United%20States&state=${state}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok)
        setHcities([]);
      else
        setHcities((await response.json()).data);

    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }

  const loadOfficeCities = async (state) => {
    try {
      const response = await fetch(`https://countriesnow.space/api/v0.1/countries/state/cities/q?country=United%20States&state=${state}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok)
        setOcities([]);
      else
        setOcities((await response.json()).data);

    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }

  useEffect(() => {
    const objCustom = lstCustomer.find(x => x.id === Cookies.get('CustomerId'));

    if (objCustom) {
      var tmpCust = { ...Customers };
      tmpCust.email = objCustom?.email ?? '';
      tmpCust.name = objCustom?.name ?? '';
      tmpCust.phone_number = objCustom?.phone_number ?? '';
      tmpCust.home_address1 = objCustom?.home_address1 ?? '';
      tmpCust.home_address2 = objCustom?.home_address2 ?? '';
      tmpCust.home_zip = objCustom?.home_zip ?? '';
      tmpCust.home_city = objCustom?.home_city ?? '';
      tmpCust.home_state = objCustom?.home_state ?? '';
      tmpCust.home_country = objCustom?.home_country ?? '';
      tmpCust.office_address1 = objCustom?.office_address1 ?? '';
      tmpCust.office_address2 = objCustom?.office_address2 ?? '';
      tmpCust.office_zip = objCustom?.office_zip ?? '';
      tmpCust.office_city = objCustom?.office_city ?? '';
      tmpCust.office_state = objCustom?.office_state ?? '';
      tmpCust.office_country = objCustom?.office_country ?? '';

      setObjCustomer(tmpCust);
      setisload(false);
    }

  }, [lstCustomer]);

  //#endregion

  //#region Save Data

  const handleSubmit = async (values) => {
    if (objCustomer.email !== values.email) {
      setPopup(<Verify_by_otp email={values.email} phoneNumber={null} data={values} onSuccess={checkPhoneNumber} onError={ClosePopup} />);
    }
    else {
      await checkPhoneNumber(values);
    }
  }

  const checkPhoneNumber = async (values) => {
    if (objCustomer.phone_number !== values.phone_number) {
      setPopup(<Verify_by_otp email={null} phoneNumber={values.phone_number} data={values} onSuccess={SaveData} onError={ClosePopup} />);
    }
    else {
      await SaveData(values);
    }
  }

  const SaveData = async (values) => {
    ClosePopup();

    const CustomerId = Cookies.get('CustomerId');
    if (CustomerId) {
      const objCustom = lstCustomer.find(x => x.id === CustomerId);
      if (objCustom && await UpdateData(dbCollections.Customers, CustomerId, values)) {
        setObjCustomer(objCustom);
        Notify('Success', 'Profile Updated Successfully.');
      }
      else {
        Notify('error', 'Profile Update Fail.');
      }
    }
    else {
      if (await InsertData(dbCollections.Customers, values)) {
        Notify('Success', 'Profile Updated Successfully.');
      }
      else {
        Notify('error', 'Profile Update Fail.');
      }
    }
  }

  const ClosePopup = () => {
    setPopup('');
  }
  {/* <Formik initialValues={objCustomer} */ }
  //#endregion

  if (isload) {
    return;
  }

  return (
    <>
      <div className='px-10 w-full md:w-[calc(100vw-18rem)]'>
        <Formik initialValues={objCustomer}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, setFieldValue }) => {

            if (values.office_state && o_cities && o_cities.length === 0) {
              loadOfficeCities(values.office_state);

              if (touched.office_city) {
                setFieldValue("office_city", touched.office_city);
              }
            }

            if (values.home_state && h_cities && h_cities.length === 0) {
              loadHomeCities(values.home_state);

              if (touched.home_city) {
                setFieldValue('home_city', touched.home_city);
              }
            }

            return (
              <Form className='text-left'>
                <Field
                  name="name"
                  as={TextField}
                  label="Name *"
                  type="text"
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  fullWidth
                  margin="normal"
                />
                <Field
                  name="phone_number"
                  as={TextField}
                  label="Phone Number *"
                  type="phone"
                  error={touched.phone_number && !!errors.phone_number}
                  helperText={touched.phone_number && errors.phone_number}
                  fullWidth
                  margin="normal"
                />
                <Field
                  name="email"
                  as={TextField}
                  label="Email *"
                  type="email"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  fullWidth
                  margin="normal"
                />

                <h1 className='mt-10 font-semibold text-xl'>Home Address</h1>
                <Field
                  name="home_address1"
                  as={TextField}
                  label="Address1"
                  type="text"
                  error={!!errors.home_address1}
                  helperText={touched.home_address1}
                  fullWidth
                  margin="normal"
                />
                <Field
                  name="home_address2"
                  as={TextField}
                  label="Address2"
                  type="text"
                  error={!!errors.home_address2}
                  helperText={touched.home_address2}
                  fullWidth
                  margin="normal"
                />

                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-5'>
                  <Field
                    name="home_zip"
                    as={TextField}
                    label="zip"
                    type="text"
                    error={!!errors.home_zip}
                    helperText={touched.home_zip}
                    fullWidth
                    margin="normal"
                  />

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select City</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      id="home_city"
                      name="home_city"
                      value={values.home_city}
                      onChange={(e) => setFieldValue("home_city", e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {h_cities.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.home_city && errors.home_city ? (<div>{errors.home_city}</div>) : null}

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select State</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      id="home_state"
                      name="home_state"
                      value={values.home_state}
                      onChange={(e) => {
                        setFieldValue("home_state", e.target.value);
                        // loadHomeCities(touched.home_state);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {states.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.home_state && errors.home_state ? (<div>{errors.home_state}</div>) : null}

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select Country</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      id="home_country"
                      name="home_country"
                      value={values.home_country}
                      onChange={(e) => setFieldValue("home_country", e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {countries.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.home_country && errors.home_country ? (<div>{errors.home_country}</div>) : null}
                </div>

                <h1 className='mt-10 font-semibold text-xl'>Office Address</h1>
                <Field
                  name="office_address1"
                  as={TextField}
                  label="Address1"
                  type="text"
                  error={touched.office_address1 && !!errors.office_address1}
                  helperText={touched.office_address1 && errors.office_address1}
                  fullWidth
                  margin="normal"
                />
                <Field
                  name="office_address2"
                  as={TextField}
                  label="Address2"
                  type="text"
                  error={touched.office_address2 && !!errors.office_address2}
                  helperText={touched.office_address2 && errors.office_address2}
                  fullWidth
                  margin="normal"
                />

                <div className='grid md:grid-cols-2 grid-cols-1 gap-x-5'>
                  <Field
                    name="office_zip"
                    as={TextField}
                    label="zip"
                    type="text"
                    error={!!errors.office_zip}
                    helperText={touched.office_zip}
                    fullWidth
                    margin="normal"
                  />

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select City</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      id="office_city"
                      name="office_city"
                      value={values.office_city}
                      onChange={(e) => setFieldValue("office_city", e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {/* {touched.office_city && (<MenuItem value={touched.office_city}>{touched.office_city}</MenuItem>)} */}
                      {o_cities.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.office_city && errors.office_city ? (<div>{errors.office_city}</div>) : null}

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select State</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      name="office_state"
                      id="office_state"
                      value={values.office_state}
                      onChange={(e) => {
                        setFieldValue("office_state", e.target.value);
                        // loadOfficeCities(touched.office_state);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {states.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.office_state && errors.office_state ? (<div>{errors.office_state}</div>) : null}

                  <FormControl fullWidth>
                    <InputLabel id="dropdown-label" className='mt-5'>Select Country</InputLabel>
                    <Select
                      className='mt-5'
                      labelId="dropdown-label"
                      id="office_country"
                      name="office_country"
                      value={values.office_country}
                      onChange={(e) => setFieldValue("office_country", e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {countries.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
                    </Select>
                  </FormControl>
                  {touched.office_country && errors.office_country ? (<div>{errors.office_country}</div>) : null}
                </div>

                <div className="pt-4">
                  <Button type="submit" variant="contained" color="primary">
                    Save
                  </Button>
                </div>
              </Form>
            )
          }
          }
        </Formik>
      </div>
      {popup}
    </>
  )
}