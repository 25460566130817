import React, { useEffect, useState } from 'react'
import { Product, dbCollections } from '../../../Database/tables';
import { useAlert } from '../common/message';
import Btn from '../common/btn';
import { useLocation } from 'react-router';
import { GetDownloadURL, SelectData, UpdateData, UploadFile, sortByField } from '../../../Database/db_helper';
import { IsEmpty, IsSelected } from '../../../Database/validation';
import Dropdown from '../common/Dropdown';
import { eMsg } from '../common/enum';

export default function DiamondDetail() {
  const location = useLocation();
  const showMsg = useAlert();

  const [MaterialData, setMaterialData] = useState([]);
  const [lstDiamondSize, setlstDiamondSizeData] = useState();
  const [lstMaterials, setlstMaterials] = useState();

  const [SameAllMaterialSize, setSameAllMaterialSize] = useState(false);
  const [IsAllDiamonds, setIsAllDiamonds] = useState([]);

  //#region Load Data

  const setData = async () => {
    // Set DiamondSize in List
    let dtDSize = await SelectData(dbCollections.Diamonds, {});
    sortByField(dtDSize, 'SerialNo');
    dtDSize = dtDSize.map(size => [`${size.Size} CT (${size.IsMined ? 'Mined' : 'Lab'} - ${size.eDiamondType})`, size.id]);
    setlstDiamondSizeData(dtDSize);

    // Select Materials
    const Materials = location.state.MaterialDetails;
    const dtMaterial = await SelectData(dbCollections.Material, {});
    sortByField(dtMaterial, 'SerialNo');
    setlstMaterials(dtMaterial);

    // Update Material based on Material Table
    Materials.forEach((material) => {
      material.DiamondDetails.forEach((Details) => {

        // Select Indexes for Remove extra Material Images.
        const removeIndexs = [];
        Details.Images.forEach((Img, Index) => {
          if (Img.MaterialId) {
            const objMaterials = dtMaterial.filter(obj => Img.MaterialId === obj.id);
            if (!objMaterials) {
              removeIndexs.push(Index);
            }
          }
          else {
            removeIndexs.push(Index);
          }
        });

        // Remove Extra Material Images Based on Index.
        removeIndexs.forEach((i, Index) => {
          Details.Images.splice(i - Index, 1);
        });

        // Add New Material which is not in array.
        dtMaterial.forEach((objMaterial) => {
          const objImage = Details.Images.filter(Img => objMaterial.id === Img.MaterialId);
          if (objImage.length === 0) {
            Details.Images.push({
              MaterialId: objMaterial.id,
              Material: objMaterial.Material,
              Image: [
                {
                  ImageURL: null,
                  DownloadURL: null
                }
              ]
            })
          }
        });
      })
    });

    // Set Material to Material Data for Show in Grid.
    setMaterialData(Materials);

  }

  useEffect(() => {
    setData();
  }, []);

  //#endregion

  //#region Set Data

  const addDiamondSize = (MSIndex, DSIndex) => {
    const objMaterials = [...MaterialData];
    objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds.push({
      DiamondId: null,
      DiamondSize: null,
      DiamondCount: null,
    });

    setMaterialData(objMaterials);
  }

  const removeDiamondSize = (MSIndex, DSIndex, subDSizeIndex) => {
    const objMaterials = [...MaterialData];
    objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds.splice(subDSizeIndex, 1);
    setMaterialData(objMaterials);
  }

  const setDiamondSize = (MSIndex, DSIndex, subDSizeIndex, Id) => {
    const objMaterials = [...MaterialData];

    const objDSize = lstDiamondSize.find(size => size[1] === Id);
    if (objDSize) {
      objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds[subDSizeIndex].DiamondSize = objDSize[0];
      objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds[subDSizeIndex].DiamondId = objDSize[1];
    }
    else {
      objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds[subDSizeIndex].DiamondSize = '';
      objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds[subDSizeIndex].DiamondId = 0;
    }

    setMaterialData(objMaterials);
  }

  const setDiamondCount = (MSIndex, DSIndex, subDSizeIndex, Count) => {
    const objMaterials = [...MaterialData];
    objMaterials[MSIndex].DiamondDetails[DSIndex].Diamonds[subDSizeIndex].DiamondCount = parseInt(Count);
    setMaterialData(objMaterials);
  }

  const setDiamondCarate = (MSIndex, DSIndex, Id) => {
    const objMaterials = [...MaterialData];

    const objDSize = lstDiamondSize.find(size => size[1] === Id);
    if (objDSize) {
      objMaterials[MSIndex].DiamondDetails[DSIndex].Carat = objDSize[0];
      objMaterials[MSIndex].DiamondDetails[DSIndex].DiamondId = objDSize[1];
    }
    else {
      objMaterials[MSIndex].DiamondDetails[DSIndex].Carat = '';
      objMaterials[MSIndex].DiamondDetails[DSIndex].DiamondId = 0;
    }

    setMaterialData(objMaterials);
  }

  const AddNewSize = (MIndex) => {
    // Create Material Wise Image List for new Size
    const objImage = [];
    lstMaterials.forEach((material) => {
      objImage.push({
        MaterialId: material.id,
        Material: material.Material,
        Image: [
          {
            ImageURL: null,
            DownloadURL: null
          }
        ]
      });
    });

    // Add New Size Data into Material Data
    const objMaterial = [...MaterialData];
    objMaterial[MIndex].DiamondDetails.push({
      Carat: null,
      DiamondId: null,
      Diamonds: [
        {
          DiamondId: null,
          DiamondSize: null,
          DiamondCount: null,
        }
      ],
      Images: objImage
    });

    setMaterialData(objMaterial);
  }

  const RemoveSize = (MIndex, DIndex) => {

    // Remove Size Data from Material Data
    const objMaterial = [...MaterialData];
    objMaterial[MIndex].DiamondDetails[DIndex].Diamonds = [];
    setMaterialData(objMaterial);
  }

  const RemoveMaterial = (MIndex, DIndex, IIndex) => {
    const objMaterials = [...MaterialData];
    objMaterials[MIndex].DiamondDetails[DIndex].Images.splice(IIndex, 1);
    setMaterialData(objMaterials);
  }

  const selectImages = (MIndex, DIndex, ImgIndex, files) => {
    const Images = [];

    for (const file of files) {
      Images.push({
        ImageURL: file,
        DownloadURL: null
      });
    }

    const objMaterials = [...MaterialData];
    objMaterials[MIndex].DiamondDetails[DIndex].Images[ImgIndex].Image = Images;
    setMaterialData(objMaterials);

    // files.forEach((file) => {
    //   Images.push({
    //     ImageURL: file,
    //     DownloadURL: null
    //   });
    // });
  }

  const selectForAllDiamond = (Index) => {
    if (IsAllDiamonds.length === MaterialData.length) {
      const tmpSetAllDiamonds = [...IsAllDiamonds];
      tmpSetAllDiamonds[Index] = !IsAllDiamonds[Index];
      setIsAllDiamonds(tmpSetAllDiamonds);
    }
    else {
      const newArray = Array.from({ length: MaterialData.length }, (_, index) => false);
      newArray[Index] = true;
      setIsAllDiamonds(newArray);
    }
  }

  //#endregion

  //#region Save Data

  const IsValid = async () => {
    try {
      for (let material of MaterialData) {
        const MIndex = MaterialData.indexOf(material);
        if (!SameAllMaterialSize || MIndex === 0) {

          for (let DiamondSize of material.DiamondDetails) {
            const DIndex = material.DiamondDetails.indexOf(DiamondSize);

            for (const Diamond of DiamondSize.Diamonds) {
              if (!IsSelected(Diamond.DiamondId) || IsEmpty(Diamond.DiamondCount) || Diamond.DiamondCount === 0) {
                showMsg(eMsg.Info, 'Please Select Diamond and Enter Diamond Counts.');
                return false;
              }
            }

            if (!IsAllDiamonds[MIndex] || DIndex === 0) {

              for (const image of DiamondSize.Images) {
                for (const img of image.Image) {
                  if (!img.ImageURL && IsEmpty(img.DownloadURL)) {
                    showMsg(eMsg.Info, `Please Select Image For Material ${image.Material}`);
                    return false;
                  }

                  if (img.ImageURL instanceof File) {
                    const URL = await UploadFile(img.ImageURL, 'images/Product');
                    const DownloadURL = await GetDownloadURL(URL);
                    img.ImageURL = URL;
                    img.DownloadURL = DownloadURL;
                  }
                }
              }

            }
            else {
              DiamondSize.Images = material.DiamondDetails[0].Images;
            }
          }

        }
        else {
          material.DiamondDetails = MaterialData[0].DiamondDetails;
        }
      }
    } catch (err) { console.log(err); }
    
    return true;
  }

  const SaveData = async () => {
    if (await IsValid()) {
      const objProduct = { ...Product };
      objProduct.MaterialDetails = MaterialData;

      await UpdateData(dbCollections.Product, location.state.id, objProduct);
      showMsg(eMsg.Success, 'Details Updated Successfully.');
    }
    else {

    }
  }

  //#endregion

  return (
    <div className="rounded-lg mt-3">
      <input id='ForAll' name='ForAll' className='w-3 h-3 mr-2' type='checkbox' checked={SameAllMaterialSize} onChange={() => setSameAllMaterialSize(!SameAllMaterialSize)} />
      <label for='ForAll'>Set Same Diamond Details and Images for All Material Size.</label>
      {MaterialData.map((MSize, Index) => (!SameAllMaterialSize || Index === 0) && (
        <div key={Index} className="p-5 bg-gray-100 mt-5">
          <div className='w-full mb-5 rounded-lg bg-white p-4 lg:flex'>
            <h4>Material Size: {MSize.Size}</h4>
            <div className='ml-auto'>
              <input id={`ForAllDiamonds ${Index}`} className='w-3 h-3 mr-2 my-auto' type='checkbox' checked={IsAllDiamonds[Index]} onChange={() => selectForAllDiamond(Index)} />
              <label for={`ForAllDiamonds ${Index}`}>Set Same Images for All Diamond Size (MaterialSize Wise).</label>
            </div>
          </div>
          {
            MSize.DiamondDetails.map((Ddetail, index) => (
              <div key={index} className={`grid grid-cols-${Ddetail.Images.length + 1} gap-4 mb-6`}>
                <div className="product_material_Image text-md text-left p-4 bg-white rounded-lg">
                  <div className='flex'>
                    <h4 className='my-auto'>Diamonds Size: &nbsp;</h4>
                    <Dropdown className='w-32' Name='Size' Value={Ddetail.DiamondId} onSelectChange={(sizeId) => setDiamondCarate(Index, index, sizeId)} AddItem='False' items={lstDiamondSize} />
                    <button type="button" onClick={() => RemoveSize(Index, index)} className="remove_material text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                      </svg>
                    </button>
                  </div>
                  {
                    Ddetail.Diamonds.map((Diamonds, i) => (
                      <div key={i} className='w-f-content flex mt-2 text-sm'>
                        <Dropdown className='w-32' Name='Size' Value={Diamonds.DiamondId} onSelectChange={(sizeId) => setDiamondSize(Index, index, i, sizeId)} AddItem='False' items={lstDiamondSize} />
                        <input value={Diamonds.DiamondCount} onChange={(e) => setDiamondCount(Index, index, i, e.target.value)} type='number' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ml-2 w-24 p-2.5' />
                        <Btn Class='text-red-500 text-lg p-0 ml-2 mb-0' onClick={() => removeDiamondSize(Index, index, i)}><i className='fa fa-trash'></i></Btn>
                      </div>
                    ))
                  }
                  <Btn Class='float-left mt-2 hover:bg-black hover:text-white' Name='Add Diamond' onClick={() => addDiamondSize(Index, index)}></Btn>
                </div>
                {
                  Ddetail.Images.map((Images, i) => (!IsAllDiamonds[Index] || index === 0) && (
                    <div key={i} className="product_material_Image flex items-center p-4 bg-white rounded-lg">
                      <div className="text-md w-full h-full">

                        <div className="flex items-center justify-between border-b rounded-t light:border-gray-600">
                          <h4>{Images.Material}</h4>
                          <button type="button" onClick={() => RemoveMaterial(Index, index, i)} className="remove_material text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                              <path stroke="currentColor" strokeOpacity="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                          </button>
                        </div>

                        <input type='file' onChange={(e) => selectImages(Index, index, i, e.target.files)} className='border-1 rounded border-black p-1 w-full mt-1' multiple></input>
                        <div className='flex overflow-auto'>
                          {Images.Image.map((img) => (
                            <div>
                              {img.DownloadURL && (<img className='mt-2 rounded-2xl max-w-44 max-h-44' src={img.DownloadURL} alt='product'></img>)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            ))
          }
          <Btn Class='mt-2 m-0 hover:bg-black hover:text-white' Name='Add Diamond Size' onClick={() => AddNewSize(Index)}></Btn>
        </div>
      ))}
      <Btn Name='Save' onClick={SaveData} Class='text-white fixed bottom-1 bg-blue-700 hover:bg-blue-800 light:bg-blue-600 light:hover:bg-blue-700 light:focus:ring-blue-800' />
    </div>
  )
}