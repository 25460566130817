import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import logo from '../website/Images/logo/logo.png';

import { firestore } from '../Database/firestore';
import { getDocs, collection, query, where, and, or } from '@firebase/firestore';
import { InsertData, UpdateData } from '../Database/db_helper';
import { Customers, dbCollections } from '../Database/tables';

import { useNotify } from '../website/Components/Notify';
import { IsEmail, IsPhone } from '../Database/validation';
import Verify_by_otp from './verify_by_otp';


const usPhoneRegex = /^(\+1|1)?\s*\(?([2-9][0-8][0-9])\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/;

const RegistrationSchema = Yup.object().shape({
  name: Yup.string().required('name is required.'),
  phone_number: Yup.string().matches(usPhoneRegex, 'Phone number must be exactly 10 digits').nullable(),
  email: Yup.string().required('email is required.').email('email is invalid.'),
  password: Yup.string().required('password is required.'),
  confirm_password: Yup.string().required('confirm password is required.'),
});

function RegistrationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const Notify = useNotify();

  const [CustomerId, setCostomerId] = useState(location.state);
  const [popup, setPopup] = useState('');

  const handleSubmit = async (values) => {
    // event.preventDefault();

    // Basic validation
    if (!values.name || !values.email || !values.password || !values.phone_number || !values.confirm_password) {
      Notify('error', 'Please fill all required fields.');
      return;
    }

    if (values.password !== values.confirm_password) {
      Notify('error', 'Confirm Password not match with Password.');
      return;
    }

    if (!IsEmail(values.email)) {
      Notify('error', 'enter valid email.');
      return;
    }

    if (!IsPhone(values.phone_number)) {
      Notify('error', 'enter valid phone number.');
      return;
    }

    const existingUser = await checkExistingUser(values.email, values.phone_number, values.password);
    if (existingUser) {
      Notify('error', 'This email or contact number is already used by another person. Try a different one.');
      return;
    }

    setPopup(<Verify_by_otp email={values.email} phoneNumber={values.phone_number} data={values} onSuccess={SaveData} onError={ClosePopup} />);
  }

  const SaveData = async (values) => {
    ClosePopup();

    const userData = { ...Customers };
    userData.name = values.name;
    userData.email = values.email.toLowerCase();
    userData.phone_number = values.phone_number;
    userData.password = values.password;

    // Update data or Insert new data based on edit mode
    if (CustomerId) {
      const IsEdit = await UpdateData(dbCollections.Customers, 'Id', userData);
      if (IsEdit) {
        Notify('Success', 'Updated Successfully.');
      }
      else {
        Notify('Error', 'Registration Fail.');
      }
    } else {
      const IsInsert = await InsertData(dbCollections.Customers, userData);
      if (IsInsert) {
        Notify('Success', 'You are Registered Successfully.');
        // SendMail('shivanjsingh4@gmail.com', 'All Jewelz Login', 'Login Success ' + values.name);
        navigate('/registration');
      }
      else {
        Notify('Error', 'Registration Fail.');
      }
    }
  }

  const ClosePopup = () => {
    setPopup('');
  }

  const checkExistingUser = async (email, contactNumber, password) => {
    let qry = query(collection(firestore, dbCollections.Customers), and(or(where('email', '==', email.toLowerCase()), where('phone_number', '==', contactNumber)), where('password', '==', password)));

    const data = [];
    (await getDocs(qry)).forEach(doc => { data.push(doc.id); });
    if (data.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <div className="registration-container">
      <div className="registration-form-box absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-md px-14 py-10 z-10" style={{ top: '50vh' }}>

        <img src={logo} alt='All Jewelz' className='mx-auto w-32'></img>
        <h1 className="text-xl mt-2 font-bold mb-4">Welcome to All Jewelz!</h1>
        <Formik initialValues={{ name: '', phone_number: '', email: '', password: '', confirm_password: '' }}
          validationSchema={RegistrationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          }}>
          {({ errors, touched }) => (
            <Form className='text-left'>
              <Field
                name="name"
                as={TextField}
                label="Name *"
                type="text"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                fullWidth
                margin="normal"
              />
              <Field
                name="phone_number"
                as={TextField}
                label="Phone Number *"
                type="text"
                error={touched.phone_number && !!errors.phone_number}
                helperText={touched.phone_number && errors.phone_number}
                fullWidth
                margin="normal"
              />
              <Field
                name="email"
                as={TextField}
                label="Email *"
                type="email"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                fullWidth
                margin="normal"
              />
              <Field
                name="password"
                as={TextField}
                label="Password *"
                type="password"
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                fullWidth
                margin="normal"
              />
              <Field
                name="confirm_password"
                as={TextField}
                label="Confirm Password *"
                type="password"
                error={touched.confirm_password && !!errors.confirm_password}
                helperText={touched.confirm_password && errors.confirm_password}
                fullWidth
                margin="normal"
              />
              <button type="submit" className="w-full py-2 mt-4 rounded-md bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-50">
                Create Account
              </button>
              <div className="border-t border-gray-300 my-5"></div>
              <div className="text-sm text-center">
                Already have account? <Link to='/registration' className="font-medium cursor-pointer text-indigo-600 hover:text-indigo-500">SignIn</Link>
              </div>
            </Form>)}
        </Formik>
      </div >
      {popup}
    </div >
  );
}

export default RegistrationPage;