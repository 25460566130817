import React, { useEffect, useState } from 'react'
import Review from './Review';

export default function Description({ Material, Karat, Weight, Diamonds, lstDiamond, Price, config, ExtraMaterialCharge, MakingCharge }) {
  const [IsReview, setIsReview] = useState(false);

  const [tr, settr] = useState([]);

  useEffect(() => {
    const tmptr = [];

    let MaterilPrice = 0;
    if (Material) {
      MaterilPrice = (Karat ? Math.round(Material.Price * Karat.Karat / 24) : Material.Price) * Weight;
      MaterilPrice += Math.round(MaterilPrice * 5 / 100);

      tmptr.push(<tr>
        <td>{Material.Material} {Karat && '(' + Karat.Karat + 'k)'}</td>
        <td>{Weight} Gram</td>
        <td className='text-right'>${MaterilPrice}</td>
      </tr>);
    }

    let DiamondPrice = 0;
    if (Diamonds) {
      try {
        Diamonds.Diamonds.forEach((objDiamond, Index) => {
          const tmpDiamond = lstDiamond.find(diamond => diamond.id === objDiamond.DiamondId);
          var tmpDiamondPrice = objDiamond.DiamondCount * tmpDiamond.Price;
          DiamondPrice = DiamondPrice + tmpDiamondPrice;
          if (tmpDiamond) {
            tmptr.push(<tr key={Index}>
              <td>{tmpDiamond.IsMined === true ? 'Mined' : 'Lab'} {tmpDiamond.eDiamondType} ({objDiamond.DiamondCount} Nos.)</td>
              <td>{objDiamond.DiamondSize}</td>
              <td className='text-right'>${tmpDiamondPrice}</td>
            </tr>);
          }
        });
      } catch { }
    }

    const MakingCost = Math.round(MaterilPrice * (MakingCharge ?? 0) / 100);
    tmptr.push(<tr key='Making'>
      <td>Making Charge ({MakingCharge ?? 0}%)</td>
      <td>-</td>
      <td className='text-right'>${MakingCost}</td>
    </tr>)

    let ExtraCharges = 0;
    if(ExtraMaterialCharge && ExtraMaterialCharge > 0){
      ExtraCharges = ExtraMaterialCharge;
      tmptr.push(<tr key='ExtraMaterial'>
        <td>Other Materials</td>
        <td>-</td>
        <td className='text-right'>${ExtraMaterialCharge}</td>
      </tr>)
    }

    let SubTotalPrice = Math.round(MaterilPrice + MakingCost + DiamondPrice + ExtraCharges);
    tmptr.push(<tr key='Subtotal'>
      <td>Subtotal</td>
      <td>-</td>
      <td className='text-right'>${SubTotalPrice}</td>
    </tr>)

    tmptr.push(<tr key='GST'>
      <td>GST ({config.GST} %)</td>
      <td>-</td>
      <td className='text-right'>${parseInt((SubTotalPrice) * config.GST / 100)}</td>
    </tr>)

    tmptr.push(<tr key='GrandTotal'>
      <td>Grand Total</td>
      <td>-</td>
      <td className='text-right'>${Price}</td>
    </tr>)

    settr(tmptr);

  }, [Material, Karat, Weight, Diamonds, Price]);

  return (
    <div className="woocommerce-tabs wc-tabs-wrapper tabs-layout mt-12">
      <ul className="space-x-6 flex whitespace-nowrap mb-px w-fit mx-auto">
        <li>
          <h2>
            <a className={`flex text-lg leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px text-black hover:border-black light:text-black light:hover:border-slate-700 ${!IsReview ? 'border-current' : 'border-transparant'}`} onClick={() => setIsReview(false)}>Description</a>
          </h2>
        </li>
        <li>
          <h2>
            <a className={`flex text-lg leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px text-black hover:border-black light:text-black light:hover:border-slate-700 ${IsReview ? 'border-current' : 'border-transparant'}`} onClick={() => setIsReview(true)}>Reviews (2)</a>
          </h2>
        </li>
      </ul>
      {!IsReview && (<div className="mt-50px">
        <a className="accordion-title" style={{ fontFamily: 'none', fontSize: '24px' }}>
          <span>Product Details</span>
        </a>
        <div className="mx-auto sm:w-full md:w-2/4 mt-10">
          <table className='w-full text-left mb-3 leading-10'>
            <tbody>
              {tr}
            </tbody>
          </table>
          <label>* Weight may vary in the final product. Differential amount if any, will be charged extra.</label>
        </div>
      </div>)}
      {IsReview && (<div className="tab-content-wrap">
        <Review />
      </div>)}

    </div>
  )
}
