import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie'
import { useData } from '../../Data';
import { useNavigate } from 'react-router-dom';
import { CartItems, dbCollections } from '../../../Database/tables';
import { InsertData } from '../../../Database/db_helper';
import { useNotify } from '../../Components/Notify';

export default function Item({ product }) {
    const { lstMaterial, lstPurity, lstDiamond, objConfig, lstWishlist, ToggleWishlist } = useData();
    const Notify = useNotify();

    const navigate = useNavigate();

    const [ImageURL, setImageURL] = useState();
    const [ImageURL2, setImageURL2] = useState();
    const [isHovered, setIsHovered] = useState(false);
    const [objDiamondSize, setobjDiamondSize] = useState();
    const [objColor, setobjColor] = useState();

    const [Price, setPrice] = useState();

    const loadDiamondSize = () => {
        if (product) {
            const objMaterial = product.MaterialDetails[0];
            if (objMaterial) {
                if (!objDiamondSize && objMaterial.DiamondDetails.length > 0) {
                    setobjDiamondSize(objMaterial.DiamondDetails[0]);
                }

                if (objMaterial.DiamondDetails.length > 0 && objMaterial.DiamondDetails[0].DiamondId !== 0) {
                    return (<><span className="text-secondary whitespace-nowrap mr-3 text-wrap">Diamond Size</span>
                        <div className="cursor-pointer text-gray-400 d-flex">
                            {
                                objMaterial.DiamondDetails.map(DDetail => DDetail.DiamondId !== 0 && (<span key={objMaterial.id} className={`swatch-square w-20 text-center whitespace-nowrap hover:text-white hover:bg-pink-500 ${objDiamondSize && DDetail.Carat === objDiamondSize.Carat ? 'text-white bg-pink-500' : ''}`} onClick={() => setobjDiamondSize(DDetail)}>{DDetail.Carat}</span>))
                            }
                        </div>
                    </>
                    )
                }
            }
        }
    }

    const loadColors = () => {
        if (objDiamondSize) {

            return objDiamondSize.Images.map((ImgMaterial, Index) => {
                const objMaterial = lstMaterial.find(material => material.id === ImgMaterial.MaterialId);
                if (!objColor && objMaterial && Index === 0) {
                    setobjColor(lstMaterial[0]);
                }
                if (objMaterial) {
                    return (<li key={Index} onClick={() => setobjColor(objMaterial)} >
                        <span title={`${objMaterial.Material}`} className={`block p-1 border-2 hover:border-[${objMaterial.Color}] rounded-full hover:scale-105 duration-300 ${objColor && objMaterial.Color === objColor.Color ? `border-[${objColor.Color}]` : ''}`}>
                            <a className={`block w-3 h-3  bg-[${objMaterial.Color}] rounded-full`}></a>
                        </span>
                    </li>);
                }
            });
        }
    }

    useEffect(() => {
        // Set Price
        let DiamondPrice = 0;
        if (lstDiamond && objDiamondSize) {
            objDiamondSize.Diamonds.forEach(DSize => {
                const objDiamond = lstDiamond.find(diamond => diamond.id === DSize.DiamondId);
                if (objDiamond) {
                    DiamondPrice += DSize.DiamondCount * objDiamond.Price;
                }
            });
        }

        const tmplstPurity = lstPurity.filter(purity => purity.MaterialId === objColor?.id);
        let MaterialPrice = 0;
        if (product.MaterialDetails[0].MaterialWeight && objColor) {
            MaterialPrice = product.MaterialDetails[0].MaterialWeight * (tmplstPurity.length > 0 ? Math.round(objColor.Price * tmplstPurity[0].Karat / 24) : objColor.Price);
        }

        MaterialPrice += Math.round(MaterialPrice * 5 / 100);
        const MakingCost = Math.round(MaterialPrice * (product?.MakingCharges ?? 100) / 100);
        let tmpPrice = Math.round(DiamondPrice + MaterialPrice + MakingCost + (product?.ExtraMaterialCharge ?? 0));
        const GSTPrice = tmpPrice * objConfig.GST / 100;
        setPrice(parseInt(tmpPrice + GSTPrice));



        if (objDiamondSize && objColor) {
            const Img = objDiamondSize.Images.find(DSize => DSize.MaterialId === objColor.id);
            if (Img) {
                try {
                    setImageURL(Img.Image[0].DownloadURL);
                    setImageURL2(Img.Image[1].DownloadURL);
                } catch (error) {
                    setImageURL('');
                    setImageURL2('');
                }
            }
        }
    }, [objDiamondSize, objColor]);

    const ShowProduct = () => {
        navigate('/product', { state: product });
    }

    const AddToCart = async () => {
        const CustomerId = Cookies.get('CustomerId');

        if (!CustomerId) {
            navigate('/registration');
            return;
        }
        else if (product && objDiamondSize && objColor && Price) {
            const objCartItem = { ...CartItems };
            objCartItem.customer_id = CustomerId;
            objCartItem.product_id = product.id;
            objCartItem.quantity = 1;
            objCartItem.name = product.ProductName;
            objCartItem.material_id = objColor.id;
            objCartItem.product_size = product?.MaterialDetails.length > 1 ? product?.MaterialDetails[0].Size : '';
            objCartItem.material_weight = product?.MaterialDetails[0]?.MaterialWeight ?? '0';
            objCartItem.price = Price;
            objCartItem.image_url = ImageURL;

            // set default first Materil_Karat as Karat
            const tmplstPurity = lstPurity.filter(purity => purity.MaterialId === objColor.id);
            if (tmplstPurity.length > 0) {
                objCartItem.karat_id = tmplstPurity[0].Karat;
            }

            // set Diamond Details
            objCartItem.diamond_id = objDiamondSize.DiamondId;
            let DDetail = '';
            objDiamondSize.Diamonds.forEach(D => { DDetail += `Size: ${D.DiamondSize} qty: ${D.DiamondCount}\n` });
            objCartItem.diamond_details = DDetail;

            let IsInserted = await InsertData(dbCollections.CartItems, objCartItem);
            if (IsInserted) {
                Notify('success', 'Item Added to cart successfully.');
            }
            else {
                Notify('error', 'Sorry!\nItem Not added into cart.');
            }
        }
    }

    return (
        <>
            <div className="max-w-md w-full mx-auto shadow-lg rounded-xl p-6" data-aos='fade-down' data-aos-delay="50">
                <div className="flex flex-col ">
                    <div className="">
                        <div className="relative max-h-72 w-full h-full mb-3 overflow-hidden rounded-2xl" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                            <div className="absolute flex flex-col top-0 right-0 p-3">
                                <button title='add to wishlist' className={`transition ease-in duration-300 shadow hover:shadow-md rounded-full w-8 h-8 text-center p-1 ${product ? (lstWishlist.find(id => id === product.id) ? 'text-pink-500' : 'hover:scale-110') : ''}`} onClick={() => ToggleWishlist(product.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox={product ? (lstWishlist.find(id => id === product.id) ? '-5 -3 32 32' : '0 0 24 24') : ''} stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={product && lstWishlist.find(id => id === product.id) ? '10' : '2' } d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                </button>
                            </div>
                            <img src={isHovered && ImageURL2 ? ImageURL2 : ImageURL} onClick={() => ShowProduct()} loading='lazy' alt="Just a flower" className="max-h-72 max-w-full object-fill mx-auto rounded-2xl duration-1000" />
                        </div>
                        <div className="flex-auto justify-evenly">
                            <div className="flex flex-wrap ">
                                <div className="flex items-center w-full justify-between min-w-0 ">
                                    <h2 className="md:text-lg text-md mr-auto truncate ">{product?.ProductName}</h2>
                                </div>
                            </div>
                            <div className="text-xl text-left font-semibold mt-1">${Price}.00</div>
                            <div className="text-left text-sm text-gray-600 mt-2">
                                <div className="flex items-center  mb-3 h-6">
                                    <span className="text-secondary whitespace-nowrap mr-3">Material</span>
                                    <div className="w-full flex-none text-sm flex items-center text-gray-600">
                                        <ul className="flex flex-row justify-center items-center space-x-2">
                                            {loadColors()}
                                        </ul>
                                    </div>
                                </div>
                                <div className='w-full pt-3 overflow-y-visible overflow-x-auto'>
                                    <div className="flex items-center mb-3 h-10">
                                        {loadDiamondSize()}
                                    </div>
                                </div>
                            </div>
                            <div className="flex space-x-2 text-sm font-medium justify-start">
                                <button onClick={() => AddToCart()}
                                    title='Add to cart' className="transition ease-in duration-100 inline-flex items-center text-sm font-medium mb-2 md:mb-0 bg-purple-500 px-5 py-2 hover:shadow-lg tracking-wider text-white rounded-full hover:bg-purple-600 hover:scale-105">
                                    <span>Add To Cart</span>
                                </button>
                                <button onClick={() => ShowProduct()} title='See Product'
                                    className="transition ease-in duration-100 bg-gray-700 hover:bg-gray-800 border hover:border-gray-500 border-gray-700 hover:text-white  hover:shadow-lg text-gray-400 rounded-full w-9 h-9 text-center p-2 hover:scale-105">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
