import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import PopupCheckout from '../Shop/popup/PopupCheckout';

import { useNotify } from '../../Components/Notify';
import { useData } from '../../Data';
import { DeleteData, GetTime } from '../../../Database/db_helper';
import { Order, dbCollections } from '../../../Database/tables';
import { eOrderStatus } from '../../../admin/components/common/enum';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import SendMail from '../../Components/Mailer';

export default function ShoppingCart() {

    const Notify = useNotify();
    const navigate = useNavigate();

    //#region  Load Data

    const { lstMaterial, lstPurity, lstCartItems, lstDiamond, lstCustomer } = useData();

    const [CartItems, setCartItems] = useState([]);

    useEffect(() => {
        setCartItems(lstCartItems);
    }, [lstCartItems]);

    const renderCartItems = () => {
        return lstCartItems.map((cart, index) => {
            let objMaterial = lstMaterial.find(material => material.id === cart.material_id);
            let objPurity = lstPurity.find(material => material.id === cart.karat_id);
            let objDiamond = lstDiamond.find(material => material.id === cart.diamond_id);

            return (<li className="xs:flex" key={cart.id}>
                <div className='w-full items-start relative'>
                    <button type="button" onClick={() => RemoveItemFromCart(index)} className="absolute right-2 top-2 ml-auto bg-transparent bg-gray-200 text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center light:hover:bg-gray-600 light:hover:text-white" data-modal-toggle="crud-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Remove Item</span>
                    </button>
                </div>
                <div className='p-2 my-auto flex'>
                    <img src={cart.image_url} loading='lazy' alt="Front of men&#039;s Basic Tee in black." className="h-auto rounded-md object-center object-cover" />
                </div>

                <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                    <div className="relative pr-9">
                        <div className='mb-4'>
                            <div className="flex justify-between">
                                <h3 className="text-sm">
                                    <a href="#" className="font-medium text-gray-700 hover:text-gray-800">
                                        {cart.name}
                                    </a>
                                </h3>
                            </div>
                            <div className="mt-1 mb-1 flex text-sm">
                                <p className="text-gray-500">{objMaterial?.Material}{objPurity && ' (' + objPurity.Karat + 'k)'}</p>
                                {objDiamond && (<div className="ml-4 border-l border-gray-200 pl-4">
                                    <p className="text-gray-500">Diamond : {objDiamond.Size} CT</p>
                                </div>)}
                            </div>
                            <p className="text-sm font-medium text-gray-900 sm:mt-0 sm:col-span-2 text-left">
                                ${cart.price}.00
                            </p>
                        </div>

                        <div className="mt-4 sm:mt-0 sm:pr-9">
                            <label htmlFor="quantity" className="sr-only">
                                Quantity
                            </label>
                            <div className="flex items-center h-full">
                                <button onClick={() => DecreaseQuantity(index)} className="group rounded-l-xl px-3 py-3 border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300">
                                    <svg className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black h-4 w-4" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M16.5 11H5.5" stroke="" strokeWidth="1.6" stroke-linecap="round"></path>
                                        <path d="M16.5 11H5.5" stroke="" strokeOpacity="0.2" strokeWidth="1.6" stroke-linecap="round"></path>
                                        <path d="M16.5 11H5.5" stroke="" strokeOpacity="0.2" strokeWidth="1.6" stroke-linecap="round"></path>
                                    </svg>
                                </button>
                                <input type="text" value={cart.quantity} onChange={(e) => updateQuantity(index, e.target.value)} className="border-y border-gray-200 outline-none text-gray-900 font-semibold text-xs w-full max-w-20 min-w-10 placeholder:text-gray-900 py-3  text-center bg-transparent" />
                                <button onClick={() => IncreaseQuantity(index)} className="group rounded-r-xl px-3 py-3 border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-50 hover:border-gray-300 hover:shadow-gray-300 focus-within:outline-gray-300">
                                    <svg className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
                                        <path d="M11 5.5V16.5M16.5 11H5.5" stroke="" strokeWidth="1.6" stroke-linecap="round"></path>
                                        <path d="M11 5.5V16.5M16.5 11H5.5" stroke="" strokeOpacity="0.2" strokeWidth="1.6" stroke-linecap="round"></path>
                                        <path d="M11 5.5V16.5M16.5 11H5.5" stroke="" strokeOpacity="0.2" strokeWidth="1.6" stroke-linecap="round"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>);
        });
    }

    //#endregion

    //#region Data Change Update

    const DecreaseQuantity = (Index) => {
        const tmpCartItems = [...CartItems];
        if (tmpCartItems[Index].quantity > 1) {
            tmpCartItems[Index].quantity -= 1;
            setCartItems(tmpCartItems);
        }
    }

    const IncreaseQuantity = (Index) => {
        const tmpCartItems = [...CartItems];
        tmpCartItems[Index].quantity += 1;
        setCartItems(tmpCartItems);
    }

    const updateQuantity = (Index, value) => {
        const tmpCartItems = [...CartItems];
        if (!value || value > 0) {
            tmpCartItems[Index].quantity = value ? parseInt(value) : 1;
            setCartItems(tmpCartItems);
        }
    }

    const RemoveItemFromCart = async (Index) => {
        await DeleteData(dbCollections.CartItems, CartItems[Index].id);
    }

    const RemoveAllItems = async () => {
        CartItems.forEach((cart, i) => {
            RemoveItemFromCart(i);
        });
    }

    //#endregion

    //#region Rezorpay

    const [Popup, setPopup] = useState();

    const handleCheckOut = async () => {
        try {
            // let data = {
            //     To: 'dharmikvagadiya64@gmail.com',
            //     Body: 'Order Successfully.',
            //     Username: 'Dharmik Vagadiya',
            // }
            // SendMail(data);
            // return;

            const CustomerId = Cookies.get('CustomerId');

            if (!CustomerId) {
                navigate('/registration');
                return;
            }

            const PayAmount = parseFloat(CartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0));

            if (!PayAmount || PayAmount <= 0) {
                Notify('error', 'Please Select Product for Checkout.');
                return;
            }

            const objOrder = { ...Order };
            objOrder.payment.Amount = PayAmount;

            const c_id = Cookies.get('CustomerId');
            const objCustomer = lstCustomer.find(c => c.id === c_id);
            if (objCustomer) {
                objOrder.customer.id = c_id;
                objOrder.customer.name = objCustomer.name;
            }

            objOrder.order_time = await GetTime();
            objOrder.order_status = eOrderStatus.Pending;
            objOrder.item = [];

            CartItems.forEach(cart => {
                let objMaterial = lstMaterial.find(material => material.id === cart.material_id);
                let objPurity = lstPurity.find(material => material.id === cart.karat_id);
                let objDiamond = lstDiamond.find(material => material.id === cart.diamond_id);

                const objItem = { ...Order.item[0] };
                objItem.productId = cart.id;
                objItem.quantity = cart.quantity;
                objItem.image_url = cart.image_url;
                objItem.total_price = cart.price * cart.quantity;
                objItem.price = cart.price;
                objItem.name = cart.name;

                objItem.description = `${objMaterial ? 'Material : ' + objMaterial.Material + (objPurity ? ' (' + objPurity.Karat + 'k)' : '') : ''}
                                       ${objDiamond ? '\\n Diamond : ' + objDiamond.Size : ''}`;

                objItem.material_details.Size = cart.product_size;
                objItem.material_details.name = objMaterial?.Material ?? '';
                objItem.material_details.price = objMaterial?.Price ?? '0.00';
                objItem.material_details.weight = cart?.material_weight ?? '0.00';

                objOrder.item.push(objItem);
            });

            setPopup(<PopupCheckout order={objOrder} onClose={onClose} onSuccess={RemoveAllItems} />);

        } catch (error) {
            Notify('error', error);
        }
    }

    const onClose = () => {
        setPopup('');
    }

    //#endregion

    return (
        <div className='md:p-14 sm:p-8 p-6' style={{ width: 'calc(100vw - 18rem)' }}>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <ul role="list" className="divide-y divide-gray-200 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-8">
                    {renderCartItems()}
                </ul>

                <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div className="flex justify-between text-xl font-medium mb-6">
                        <p>Order summary</p>
                    </div>
                    <div className="flex justify-between text-sm font-medium mb-6 text-gray-700">
                        <p>Subtotal</p>
                        <p>${CartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}.00</p>
                    </div>
                    <div className="flex justify-between text-sm font-medium mb-6 text-gray-700">
                        <p>Shipping estimate</p>
                        <p>$0.00</p>
                    </div>
                    <div className="flex justify-between text-sm font-medium mb-6 text-gray-700">
                        <p>Tax estimate</p>
                        <p>$0.00</p>
                    </div>
                    <div className="flex justify-between text-sm font-medium mb-6 text-gray-700">
                        <p>Order total</p>
                        <p>${CartItems.reduce((acc, item) => acc + (item.price * item.quantity), 0)}.00</p>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                    <div className="mt-6">
                        <a className="w-full flex justify-center cursor-pointer items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700" onClick={() => handleCheckOut()}>
                            Checkout
                        </a>
                    </div>
                    <div className="mt-6 flex justify-center text-sm text-gray-500">
                        <p>
                            or{' '}
                            <Link to='/shop' className="font-medium text-indigo-600 hover:text-indigo-500">
                                Continue Shopping
                                <span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            {Popup}
        </div>
    );
}