import React, { useEffect, useState } from 'react';
import { useData } from '../../Data';
import { eOrderStatus, GetEnumName } from '../../../admin/components/common/enum';
import { timestampTOstring } from '../../../Database/validation';
import { dbCollections, Order } from '../../../Database/tables';
import { GetTime, UpdateData } from '../../../Database/db_helper';
import { useNotify } from '../../Components/Notify';

export default function Orders() {
  const { lstOrders } = useData();
  const notify = useNotify();

  const [lstMyOrders, setlstorders] = useState([]);

  useEffect(() => {
    const tmpOrders = [];
    for (const objOrder of lstOrders) {
      if (objOrder && objOrder.item) {
        let Status = objOrder.order_status;
        let Time = objOrder.order_time;

        for (const item of objOrder.item) {
          tmpOrders.push({ order_status: Status, order_time: Time, id: objOrder.id, last_updatetime: objOrder.last_updatetime, ...item });
        }
      }
    }

    console.log(lstOrders);
    console.log(tmpOrders);
    setlstorders(tmpOrders);
  }, [lstOrders]);

  const CancelOrder = async (Id) => {
    const objOrder = lstOrders.find(x => x.id === Id);

    if (objOrder) {
      objOrder.order_status = eOrderStatus.Cancel;
      objOrder.last_updatetime = await GetTime();

      if (await UpdateData(dbCollections.Order, Id, objOrder)) {
        notify('success', 'Order Cancel Successfully.');
      }
      else {
        notify('error', 'Order Cancel Fail.');
      }
    }
    else {
      notify('error', 'Order Cancel Fail.');
    }
  }

  const ReturnOrder = async (Id) => {
    const objOrder = lstOrders.find(x => x.id === Id);

    if (objOrder) {
      objOrder.order_status = eOrderStatus.Return;
      objOrder.last_updatetime = await GetTime();

      if (await UpdateData(dbCollections.Order, Id, objOrder)) {
        notify('success', 'Order Return Successfully.');
      }
      else {
        notify('error', 'Order Return Fail.');
      }
    }
    else {
      notify('error', 'Order Return Fail.');
    }
  }

  return (
    <div>
      {/* <div class="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div class="flex">
          <div class="w-1/3">
            <img src="your-image-url.jpg" alt="Jewelry Image" class="w-full h-full object-cover" />
          </div>

          <div class="w-2/3 p-4">
            <h2 class="text-2xl font-bold text-gray-800">Jewelry Name</h2>
            <p class="text-gray-600">Material: Gold</p>
            <p class="text-gray-600">Purity: 24K</p>
            <p class="text-gray-600">Diamond Size: 1.5 carat</p>
            <p class="text-gray-600">Price: $5000</p>
            <p class="text-gray-600">Quantity: 2</p>

            <div class="mt-4">
              <p class="text-gray-600">Status: <span class="text-green-500">Processing</span></p>
            </div>

            <div class="mt-2">
              <p class="text-gray-600">Order Time: August 31, 2024, 14:30 PM</p>
            </div>

            <div class="mt-4">
              <button class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                Cancel Order
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <div className='lg:p-24 md:p-22 sm:p-16 xs:p-10 p-4'>
        {lstMyOrders.map((item, index) => {

          let StatusClass = '';
          switch (item.order_status) {
            case eOrderStatus.Pending:
              StatusClass = 'border-gray-500 text-gray-500';
              break;

            case eOrderStatus.Processing:
              StatusClass = 'border-yellow-500 text-yellow-500';
              break;

            case eOrderStatus.Shipping:
              StatusClass = 'border-blue-500 text-blue-500';
              break;

            case eOrderStatus.Complete:
              StatusClass = 'border-green-500 text-green-500';
              break;

            case eOrderStatus.Cancel:
              StatusClass = 'border-red-500 text-red-500';
              break;
          }

          return (
            <>
              <div key={index} class="md:flex">
                <div class="lg:w-1/2 md:w-1/3 w-full">
                  <img src={item.image_url} alt="Jewelry Image" class="w-full h-full object-cover" />
                </div>

                <div class="lg:w-1/2 md:w-2/3 w-full p-4 text-left px-16">
                  <h2 class="text-2xl font-bold text-gray-800">{item.name}</h2>
                  <p class="text-gray-600">Price: ${item.total_price}</p>
                  <p style={{ whiteSpace: 'pre-line' }}>{item.description.replace('\\n ', '')}</p>
                  <p class="text-gray-600">Quantity: {item.quantity}</p>
                  {/* <p class="text-gray-600">Material: {item.material_details.name}</p>
              <p class="text-gray-600">Purity: 24K</p>
              <p class="text-gray-600">Diamond Size: 1.5 carat</p>*/}

                  <div class="mt-4">
                    <p class="text-gray-600">Status: <span class={StatusClass}>{GetEnumName(eOrderStatus, item?.order_status ?? 1)}</span></p>
                  </div>

                  <div class="mt-2">
                    <p class="text-gray-600">Order Time: {item.order_time && timestampTOstring(item.order_time)}</p>
                  </div>

                  {item && item.order_status && item.order_status > 2 && (<div class="mt-2">
                    <p class="text-gray-600">{GetEnumName(eOrderStatus, item?.order_status ?? 1)} Time: {item.last_updatetime && timestampTOstring(item.last_updatetime)}</p>
                  </div>)}

                  <div class="mt-4">
                    {item && item.order_status && item.order_status <= eOrderStatus.Processing && (<button class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600" onClick={() => CancelOrder(item.id)}>
                      Cancel Order
                    </button>)}
                    {item && item.order_status && item.order_status == eOrderStatus.Complete && (<button class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600" onClick={() => ReturnOrder(item.id)}>
                      Return Order
                    </button>)}
                  </div>
                </div>
              </div>
              <hr className='my-16' />
            </>
          )
        })}
      </div>
    </div>
  )
}
