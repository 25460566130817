import emailjs from 'emailjs-com';

emailjs.init('Nn_rEHoba6ap-3ekc');

export default async function SendMail(to_name, order_number, orderdate, item_details, amount, address, to_email) {
    await emailjs.send("service_n9pptgr", "template_a3kkkdn", {
        to_name: to_name,
        OrderNumber: order_number,
        OrderDate: orderdate,
        Item_details: item_details,
        Total_Amount: amount,
        Address: address,
        to_email: to_email,
    });
}

export async function SendOTPMail(to_name, to_email, otp) {
    await emailjs.send("service_n9pptgr", "template_sngf3lg", {
        to_name: to_name,
        OTPCode: otp,
        to_email: to_email,
    });
}


// import emailjs from '@emailjs/browser';
// import { useNotify } from './Notify';

// export default function SendMail(data) {
//     console.log(data);

//     // const Notify = useNotify();
//     emailjs.sendForm('service_pkylnng', 'template_zgzy59w', data, 'hqukW0HKEtSqt749A')
//         .then((result) => {
//             // Notify('success', 'mail send successfully.');
//             console.log('mail send successfully.');

//         })
//         .catch((err) => {
//             // Notify('error', 'mail fail');
//             console.log('mail send fail.' + err);
//         });

//     // window.Email.send({
//     //     Host: "smtp.elasticemail.com",
//     //     auth: {
//     //         user: "dharmikvagadiya64@gmail.com",
//     //         pass: "4811813911"
//     //     },
//     //     Username: 'Dharmik Vagadiya <dharmikvagadiya64@gmail.com>',
//     //     Password: '4811813911',
//     //     To: to_mail,
//     //     From: "dharmikvagadiya64@gmail.com",
//     //     Subject: subject,
//     //     Body: body,
//     //     secure: true, // Enable SSL/TLS
//     //     SecureToken: '',
//     //     port: 587 // Replace with the appropriate port for TLS (587 is common for TLS)
//     // }).then(message => alert(message))
//     //     .catch((error) => alert(`Failed to send mail: ${error}`));
// }



// // async function sendEmailUsingSendGrid(receiverEmail, otp) {
// //     const sendGridAPIKey = 'YOUR_SENDGRID_API_KEY'; // Replace with your SendGrid API key
// //     const emailData = {
// //         personalizations: [
// //             {
// //                 to: [{ email: receiverEmail }],
// //                 subject: 'Your OTP for Verification'
// //             }
// //         ],
// //         from: { email: 'your-email@example.com' }, // Replace with your verified sender email
// //         content: [
// //             {
// //                 type: 'text/plain',
// //                 value: `Your OTP is: ${otp}. It is valid for 10 minutes.`
// //             }
// //         ]
// //     };

// //     try {
// //         const response = await fetch('https://api.sendgrid.com/v3/mail/send', {
// //             method: 'POST',
// //             headers: {
// //                 'Content-Type': 'application/json',
// //                 'Authorization': `Bearer ${sendGridAPIKey}`
// //             },
// //             body: JSON.stringify(emailData)
// //         });

// //         if (response.ok) {
// //             Notify('success', 'Email sent successfully.');
// //             return true;
// //         } else {
// //             Notify('error', 'Failed to send email:');
// //             return false;
// //         }
// //     } catch (error) {
// //         Notify('error', 'Failed to send email:');
// //         return false;
// //     }
// // }






//   // async function checkPhoneNumberStatus(phoneNumber) {
//   //   const regex = /^[0-9]{3}[0-9]{3}[0-9]{4}$/;
//   //   if (regex.test(phoneNumber))
//   //     return;

//   //   const apiUrl = `https://telecom-provider-api.com/check_number?phone=${phoneNumber}`;

//   //   try {
//   //     const response = await fetch(apiUrl);
//   //     if (!response.ok) {
//   //       throw new Error('Network response was not ok');
//   //     }
//   //     const data = await response.json();
//   //     return data.status; // Example: 'assigned' or 'unassigned'
//   //   } catch (error) {
//   //     console.error('Error checking phone number status:', error);
//   //     return null;
//   //   }
//   // }

//   // async function verifyEmailWithHunter(email) {
//   //   const apiKey = 'your_hunter_api_key'; // Replace with your Hunter.io API key
//   //   const url = `https://api.hunter.io/v2/email-verifier?email=${email}&api_key=${apiKey}`;

//   //   try {
//   //     const response = await fetch(url);
//   //     const data = await response.json();
//   //     return data.data.status === 'valid'; // Check the status returned by Hunter
//   //   } catch (error) {
//   //     console.error('Error verifying email:', error);
//   //     return false;
//   //   }
//   // }
